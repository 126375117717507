@media only screen and (max-width: 1200px) {

  .car_type_heading {
    width: 75%;
  }

  .inventory_right {
    width: 75%;
  }

  .car-type-price .price-wraper {
    padding-left: 7px;
  }
}

@media only screen and (max-width: 1170px) {

  .horizontal_box .video_details a.pull-right {
    float: left !important;
  }

  .flexslider2:hover a.flex-next {
    right:0;
  }

  .flexslider2:hover a.flex-prev {
    left: 0;
  }

  .flexslider2 a.flex-next {
    right:0;
  }

  .flexslider2 a.flex-prev {
    left: 0;
  }

  .select-wrapper {
    height: auto;
  }

  .inventory_right .wrapper_carList .car-type-price {
    padding-left:0;
    width: 50%;
    margin-left: 50px;
  }
}

@media (min-width: 1170px) {

  .controls {
    margin: 5px 0 5px 0;
  }

  .form-row {
    width: 240px;
  }

  input[type="text"], textarea, select, div.styled, input[type="file"] {
    width: 108px;
  }

  .slide-text-wrap h2 {
    font-size: 72px;
  }

  .slide-text-wrap h3 {
    font-size: 55px;
  }

  .slide-text-wrap h4 {
    font-size: 30px;
  }

  #secondary-banner {
    margin-top: 0;
    padding-top: 160px;
  }

  .boxed_layout #secondary-banner {
    margin-top: 0;
    padding-top: 160px;
  }

  #secondary-banner h2 {
    font-size: 34px;
    padding-left: 0;
    font-weight: 700;
  }

  #secondary-banner h4 {
    font-size: 22px;
    padding-left: 0;
    margin-bottom: -10px;
    font-weight: 300;
    letter-spacing: 0.1px;
    margin-top: 13px;
  }

  .breadcrumb {
    float: right;
    margin-top: 0;
    margin-bottom: -15px;
  }

  .shadow {
    background-position: center top;
    background-repeat: repeat-x;
  }

  .car-type-col-1 {
    float: left;
    width: 536px;
    margin: 0 20px;
  }

  .car-type-col-2 {
    float: left;
    width: 75px;
    margin: 50px 0;
  }

  .car-type-col-3 {
    float: right;
    width: 195px;
    position: relative;
  }

  .car-type-col-1 h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top:0;
    letter-spacing: -0.5px;
  }

  .no-left-padding {
    padding-left: 0;
    width: 50%;
  }

  .video-link {
    float: left;
    margin-left: 0;
    font-size: 12px;
    font-weight: 600;
    margin-top:0;
    text-shadow: 0 1px 0 #fff;
    color: #3a3a3a;
    padding: 4px 8px;
    margin-bottom: 0;
  }

  .video-link i {
    padding-right: 5px;
  }

  .margin-btm {
    margin-bottom:0;
  }

  .input-checkbox {
    float: right;
  }

  .car-type-col-3 form {
    float: right;
    margin: -15px -12px;
    padding: 0;
    position: relative;
    z-index: 555;
  }

  .price-wrap {
    display: block;
    margin: 30px 0 14px 0;
  }

  .price {
    font-size: 12px;
    display: block;
    text-align: left;
  }

  .price-tag {
    font-size: 36px;
    font-weight: 600;
    display: block;
    margin: 5px 0;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
  }

  .form-links {
    float: right;
    margin: 0 -12px 0 0;
    padding: 0;
    list-style: none;
  }

  .form-links li {
    float: left;
    width: auto;
    margin-right: 8px;
  }

  .top_buttons .form-links li {
    margin-right: 0;
  }

  .form-links li a {
    color: #3a3a3a;
    font-size: 12px;
    width: auto;
    text-shadow: 0 1px 0 #fff;
    font-weight: 600;
    padding-left: 8px;
    padding-right: 8px;
    text-decoration: none;
  }

  .form-links li:nth-child(3), .form-links li:nth-child(3) a {
    width: auto;
    margin: 0 7px 0 0;
  }

  .form-links li:nth-child(1) {
    margin-right: 0;
  }

  .form-links li:nth-child(1) a {
    margin-right: 0;
  }

  .ribbon-item {
    clear: none;
    display: block;
  }

  .content h2.error {
    font-size: 260px;
    display: block;
  }

  .error-message em {
    font-size: 100px;
  }

  .right_carList .check_box_right {
    position: absolute;
    right: 39px;
    top: -9px;
  }

  .car_heading {
    margin-left: 178px;
  }

  .wrapper_carList .car-type .col-width-50 {
    width: 90%;
  }

  .price_tag {
    left: 21%;
    position: absolute;
    top: -21px;
  }

  .footer-contact ul li {
    padding-right: 0;
  }

  .flip .card .back .hover_title {
    margin-top: 23px;
  }

  #current_size {
    z-index: 1170;
  }

  .flip, .flip .card, .flip .card .face {
    height: 164px;
    width: 303px;
  }

  .efficiency-rating ul li.fuel {
    margin-left: -30px;
  }

  .efficiency-rating ul li.fuel img {
    width: initial;
  }

  .boxed_layout .flip, .boxed_layout .flip .card, .boxed_layout .flip .card .face {
    width: 310px;
    height: 168px;
  }

  .boxed_layout header .bottom-header .navbar-default .navbar-nav>li>a {
    font-size: 14px;
    padding: 38px 15px 20px !important;
  }

  .boxed_layout .container {
    width: 1100px;
  }

  #secondary-banner .container .row div {
    float: none;
    display: table-cell;
    vertical-align: bottom;
    height: 90px;
  }

  .blog-content img.featured_image {
    display: inline-block;
    float: left;
    margin: 7px 25px 10px 0;
  }

  .boxed_layout, .boxed_layout header.affix, .boxed_layout header.affix-top, .boxed_layout .message-shadow {
    width: 85%;
    max-width: 1270px;
  }

  .boxed_layout .container, .boxed_layout section.content .container, .boxed_layout header .bottom-header .container {
    /*padding: 0 50px;*/
    width:1100px;
  }

  .boxed_layout .listing_sort, .boxed_layout .listing-view {
    padding-left: 0;
    padding-right: 0;
  }

  .boxed_layout #secondary-banner .container .row > .col-xs-12 {
    padding-left: 0;
  }

  .listing_sort, .listing-view {
    padding-left: 0;
    padding-right: 0;
  }

  .boxed_layout header .toolbar .container, .boxed_layout header .bottom-header .container {
    padding: 0 50px;
  }

  .toolbar .left_bar, .toolbar .container .row > div {
    padding-left: 0;
    padding-right: 0;
  }

  .boxed_layout div.inventory {
    margin: 0 0 20px;
  }

  .boxed_layout .car_listings, .boxed_layout .pagination_container, .boxed_layout .copyright-wrap .container .row > div {
    padding-left: 0;
    padding-right: 0;
  }

  .boxed_layout footer .container, .boxed_layout .copyright-wrap .container {
    /*padding: 0 35px;*/
    width:1100px;
  }

  .copyright-wrap .container .row > div, .pagination_container, .boxed_layout section.content, .boxed_layout .car-block-wrap, .boxed_layout .car-info-wrap, .boxed_layout .welcome-wrap, .boxed_layout .car-block-wrap .container .row {
    padding-left: 0;
    padding-right: 0;
  }

  .boxed_layout .message-wrap .container {
    width: 1100px;
  }

  .boxed_layout.margin header.affix-top {
    width: 100%;
    margin-top: 40px;
  }

  .boxed_layout.margin {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .select-wrapper span.sort-by {
    margin-right: 12px;
  }

  .select-wrapper.pagination .row > div:first-of-type {
    text-align: left;
  }

  .select-wrapper ul.top_buttons {
    margin-right: 0;
  }

  /*.boxed_layout .container {
      width: 100%;
  }*/

  header .bottom-header .navbar-default .navbar-brand {
    padding: 22px 0 0 0;
  }

  .boxed_layout .search-form .find_new_vehicle {
    margin-top: 10px;
  }
}

@media (max-width: 992px) {

  .boxed_layout .row {
    margin: 0;
  }

  /*.boxed_layout .navbar-header {
      float: none;
  }

  .boxed_layout .navbar-left, .boxed_layout .navbar-right {
      float: none !important;
  }

  .boxed_layout .navbar-toggle {
      display: block;
  }

  .boxed_layout .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }

  .boxed_layout .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }

  .boxed_layout .navbar-collapse.collapse {
      display: none!important;
  }

  .boxed_layout .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }

  .boxed_layout .navbar-nav>li {
      float: none;
  }

  .boxed_layout .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }

  .boxed_layout .collapse.in {
      display: block !important;
  }

  .boxed_layout header .bottom-header .navbar-default .navbar-nav>li>a {
      padding: 4px 11px !important;
  }

  .boxed_layout .navbar-nav .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
  }

  .boxed_layout header .navbar-nav li.dropdown.open:hover ul.dropdown-menu {
      display: block;
  }

  .boxed_layout header .navbar-nav li.dropdown .dropdown-menu li.dropdown:hover .dropdown-menu {
      display: block;
  }

  .boxed_layout header .navbar-default .navbar-nav>.dropdown>a .caret, .boxed_layout header .navbar-default .navbar-nav .dropdown a .caret {
      display: inline-block;
  }

  .boxed_layout header .navbar-nav li.dropdown:hover .dropdown-menu {
      display: none;
  }

  .boxed_layout header .navbar-nav li.dropdown.open:hover ul.dropdown-menu li.dropdown .dropdown-menu {
      display: none;
  }

  .boxed_layout .dropdown .dropdown-menu li.dropdown .dropdown-menu, .boxed_layout header .navbar-nav.pull-right>li>.dropdown-menu, .boxed_layout header .navbar-nav>li>.dropdown-menu {
      background: none;
  }

  .boxed_layout .affix .container .navbar .navbar-collapse .navbar-nav > li > a {
      padding: 18px 9px 18px 10px !important;
  }*/
}

@media only screen and (max-width: 568px) {

  .form-element input[type=submit] {
    margin: 10px 0 0 0;
  }
}

@media only screen and (max-width: 1000px) {

  header .bottom-header .navbar-default .navbar-nav>li>a {
    padding: 23px 4px 25px 4px !important;
  }

  .latest-news {
    clear: both;
  }

  .latest-news .recent_blog_posts {
    padding-left: 0;
  }
}

@media only screen and (max-width: 680px) {

  p {
    padding-bottom: 10px;
  }

  header .bottom-header .navbar-default .navbar-nav>li>a {
    padding: 18px 5px 18px 5px !important;
  }

  .price_tag {
    left: 50%;
    position: absolute;
    top: -7px;
  }

  .horizontal_box .video_details a.pull-right {
    float: right !important;
  }

  .car_category {
    width: 75%;
  }

  .cat_table {
    width: 90%;
  }

  .cat_accordian {
    width: 20%;
  }

  .car-type-list .list_img {
    float: left;
    width: 100%;
    text-align: center;
  }

  .car-type-list .inventory_right .car-type .col-width-50 {
    display: block !important;
    width: 90%;
  }

  .car-type-list .inventory_right .wrapper_carList .car-type {
    width: 100%;
  }

  .inventory_right .wrapper_carList .car-type-price {
    width: 100%;
    display: block;
  }

  .wrapper_carList .car-type-price .price-wraper {
    width: 100%;
    padding-left: 15px;
  }

  .bottom-header .navbar-nav {
    float: none !important;
  }

  header .bottom-header .navbar-default .navbar-nav>li>a {
    padding: 4px 11px !important;
  }

  body header.affix {
    top: -31px;
  }

  .navbar-default .navbar-toggle {
    border-color: transparent;
    background-color: transparent;
    margin-top: 17px;
  }

  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
  }

  .banner-wrap {
    margin-top:0 !important;
  }

  .navbar-collapse {
    border-top: none;
  }

  .navbar-nav {
    margin:0 -15px;
  }

  .welcome-wrap h4 {
    margin: 0;
  }

  .footer-contact {
    padding-bottom: 0;
  }

  footer div[class*="col-"] {
    padding-bottom: 25px;
  }

  header .navbar-nav.pull-right>li>.dropdown-menu, header .navbar-nav>li>.dropdown-menu {
    margin-left: 0 !important;
    background: none !important;
  }

  header .navbar-nav>li>.dropdown-menu >li>a {
    padding: 5px 15px 5px 12px;
    color: #fff !important;
  }

  header .navbar-default .navbar-nav>.dropdown>a b.caret, header .navbar-default .navbar-nav .dropdown a b.caret {
    display: inline-block;
  }

  header .navbar-nav li.dropdown:hover ul.dropdown-menu, header .navbar-nav li.dropdown .dropdown-menu li.dropdown:hover ul.dropdown-menu {
    display: none;
  }

  header .navbar-nav li.dropdown.open:hover ul.dropdown-menu {
    display: block;
  }
}

@media only screen and (max-width: 767px) {

  .horizontal_box .vehicle_details .price-wrap span.pull-right {
    float: none !important;
  }

  .horizontal_box .video_details a.pull-right {
    float: left !important;
  }

  .inventory_right {
    width: 100%;
    padding:0;
    margin-top: 10px;
  }

  .right_carList .car-type-list .list_img {
    width: 100%;
    text-align: center;
  }

  .inventory-wide-sidebar-right .car_listings.sidebar{
    margin-left: auto;
  }

  .post-entry span.tags {
    text-align: left;
  }

  .threadauthor {
    padding: 0 10px;
  }

  .navbar-default .navbar-collapse .navbar-nav > li > a {
    padding: 13px 15px 8px 15px;
  }

  .affix .container .navbar .navbar-collapse .navbar-nav > li > a {
    padding: 18px 9px 18px 10px !important;
  }

  header .bottom-header .navbar-nav {
    float: none !important;
  }

  .boxed_layout.margin header .bottom-header .navbar-nav {
    margin: 0 -15px;
  }

  header .bottom-header .navbar-default .navbar-brand {
    padding: 4px 0 0 0;
  }

  header .navbar-default .navbar-nav>.dropdown>a b.caret, header .navbar-default .navbar-nav .dropdown a b.caret {
    display: inline-block;
  }

  header .navbar-nav li.dropdown:hover ul.dropdown-menu, header .navbar-nav li.dropdown .dropdown-menu li.dropdown:hover ul.dropdown-menu {
    display: none;
  }

  header .navbar-nav li.dropdown.open:hover > ul.dropdown-menu {
    display: block;
  }

  header .navbar-nav li.dropdown.open:hover > ul.dropdown-menu ul {
    display: none;
  }

  section.banner-wrap {
    margin-top: 0;
  }

  footer .container .row > div {
    padding-bottom: 25px;
  }

  .recent-vehicles-wrap .row > div {
    clear:both;
  }

  .boxed_layout .message-wrap .container .row {
    margin: 0 -15px;
  }
}

@media (min-width: 320px) {

  .flip, .flip .card, .flip .card .face {
    width: 100%;
    max-width: 100%;
    border:0 solid #0F0;
  }

  .flip .card .back .hover_title {
    margin-top: 20px;
  }

  .flip .card .face.front {
    position: relative;
  }

  #secondary-banner h2 {
    font-size: 28px;
    margin-top: 0;
    padding-top: 0;
  }

  #secondary-banner h4 {
    font-size: 18px;
  }

  #secondary-banner .container .row {
    display: block;
  }

  #secondary-banner .container .row div {
    float: none;
    display: block;
    vertical-align: bottom;
  }

  #secondary-banner .container .row > div:first-of-type {
    min-height: 100px;
  }

  .breadcrumb {
    margin-top: 0;
    margin-bottom: 0;
    display: none;
  }

  header .left_bar {
    display: none;
  }

  .boxed_layout header .toolbar .container, .boxed_layout header .bottom-header .container {
    padding: 0 0;
  }

  header .bottom-header .navbar-default .navbar-brand {
    padding: 15px 0 0 15px;
  }

  .container .inner-page > .col-lg-9, .inner-page .container > .col-md-12 {
    padding: 0 0;
  }

  .inner-page.inventory-listing .content-nav {
    padding: 0 0;
  }

  .information_head .deparment {
    text-align: center;
  }

  .hours_operation table.table {
    text-align: center;
    width: 170px;
    margin: 0 auto;
  }

  .form-links li {
    list-style: none;
  }

  .default-btn {
    float: none;
    display: block;
    clear: both;
    margin-top: 10px;
  }

  .company_info li.address {
    display: none;
  }

  .toolbar ul > li {
    margin-right: 10px;
  }

  footer.design_2 {
    padding-bottom: 64px;
  }

  .content h2.error {
    font-size: 80px;
    line-height: 40px;
  }

  .error-message em {
    font-size: 40px;
  }

  .specialize-in {
    margin-top: 40px;
  }

  .testimonials, .latest-news, .featured-brand {
    margin-top: 20px;
  }

  .boxed_layout, .boxed_layout header.affix, .boxed_layout header.affix-top, .boxed_layout .message-shadow {
    width: 100%;
  }

  .boxed_layout .container, .boxed_layout header .bottom-header .container {
    padding: 0;
  }

  .boxed_layout .car_listings {
    padding: 0;
  }

  .listing_sort, .listing-view {
    padding-left: 15px;
    padding-right: 15px;
  }

  .boxed_layout .copyright-wrap .container .row >div {
    padding-left: 0;
    padding-right: 0;
  }

  section.content, .car-info-wrap, .message-wrap .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  /*.boxed_layout.margin header.affix-top {
      width: 100%;
      margin-top: 0;
  }*/

  /*.boxed_layout.margin {
      margin-top: 0;
  }*/

  .select-wrapper.pagination .pull-right, .select-wrapper.pagination .row > div:first-of-type {
    text-align: center;
  }

  .listing_sort .sort-by {
    margin-left: 0;
  }

  .car_listings {
    margin: 0 auto;
  }

  .inventory-wide-sidebar-left > div, .boxed_layout .listing_sort, .boxed_layout .listing-view {
    padding-right: 0;
    padding-left: 0;
  }

  .select-wrapper span.sort-by {
    margin-right: 10px;
    float: none;
    vertical-align: top;
  }

  .select-wrapper ul.top_buttons li {
    float: none;
  }

  .car_listings.boxed.boxed_right {
    margin-right: 0;
    margin-left: 0;
  }

  .listing_sort, .listing-view, .pagination_container {
    padding-right: 0;
    padding-left: 0;
  }

  .inventory-wide-sidebar-left .sidebar.car_listings {
    padding-left: 0;
  }

  .car_listings.boxed.boxed_left {
    margin-left: 0;
  }

  .search-form .find_new_vehicle {
    margin-top: 10px;
  }

  .blog-content img.featured_image {
    display: block;
    float: none;
    margin: 7px auto 10px;
  }

  .boxed_layout .navbar-collapse {
    margin: 0 0;
  }

  .toolbar ul li:last-child {
    margin-right:0;
  }

  div.example-tabs .nav-tabs>li {
    margin-bottom: 5px;
  }

  div.example-tabs .nav-tabs>li, div.example-tabs .nav-tabs>li>a {
    width: 100%;
    display: block;
  }

  .right-content, .right-content > div, .right-content > div > div {
    clear: both;
  }

  .inventory-listing .right-content {
    padding-right: 0;
    padding-left: 0;
  }

  .car-info .table>tbody>tr>td:first-child {
    padding-left: 0;
  }

  .nav-tabs {
    border: 0;
  }

  #features ul {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }

  table.left_table, table.right_table {
    width: 98%;
  }
}

@media (min-width: 480px) {

  .left-none, .right-none {
    float: none;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
  }

  .toolbar .right-none.pull-right {
    float: right;
    width: auto;
    clear: none;
    margin: 0 auto;
  }

  header {
    position: absolute;
  }

  h1 a.logo {
    margin-bottom: 20px;
  }

  .message-wrap h2 {
    text-align: center;
  }

  .social {
    margin: 10px 0 0;
  }

  .f-nav {
    width: 100%;
    display: block;
  }

  .slide-text-wrap h4 {
    letter-spacing:0;
  }

  #secondary-banner {
    margin-top: 0;
    padding-top: 164px;
  }

  #secondary-banner h2 {
    font-size: 28px;
    padding-top: 0;
    margin-top: 0;
  }

  #secondary-banner h4 {
    font-size: 20px;
  }

  .shadow {
    background-position: left top;
    background-repeat: repeat-x;
  }

  .align-right {
    float: left;
    clear: left;
    margin-right: 10px;
    line-height: 45px;
  }

  .form-links {
    margin: 0 0 20px -5px;
    float: left;
  }

  .car-type-col-1 {
    width: 100%;
    float: left;
    margin: 10px 0;
  }

  .car-type-col-1 h3 {
    font-size: 20px;
    font-weight: 600;
  }

  .page-view {
    float: left;
    margin-left: 0;
    margin-top: 10px;
  }

  .video-link {
    float: left;
    color: #3a3a3a;
    margin-left: 0;
  }

  .car-type-col-3 form {
    float: right;
    width: auto;
    margin-bottom: 5px;
  }

  .no-left-padding {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    padding-left: 0;
  }

  .car-type-col-2 {
    width: 50%;
    float: left;
  }

  .car-type-col-3 {
    width: 50%;
    float: left;
  }

  .price-wrap {
    display: block;
    margin: 30px 0 14px 0;
  }

  .img-border {
    margin: 0 20%;
  }

  .content h2.error {
    font-size: 80px;
    line-height: 50px;
  }

  .error-message em {
    font-size: 40px;
  }

  header .bottom-header .navbar-default .navbar-brand .logo {
    margin-left: 0;
    display: block;
  }

  .efficiency-rating img.aligncenter {
    left: 12px;
  }

  .view_viewport {
    font-size: 12px;
  }

  .example-tabs .nav li {
    width: initial;
    text-align: left;
  }

  .footer-contact ul li {
    padding-right: 0;
  }

  .car-rate-block .small-block {
    margin-bottom: 25px;
  }

  #current_size {
    z-index: 480;
  }

  .toolbar .row ul li.address {
    display: block;
  }

  .select_view {
    display: none;
  }

  .years-dropdown, .makers-dropdown, .models-dropdown, .body-styles-dropdown, .mileage-dropdown, .transmissions-dropdown, .fuel-economies-dropdown, .conditions-dropdown, .location-dropdown, .prices-dropdown {
    width: 100%;
  }

  .flip, .flip .card, .flip .card .face {
    height: 200px;
    width: 370px;
    border: none;
  }

  .boxed_layout header .toolbar .container, .boxed_layout header .bottom-header .container {
    padding: 0 15px;
  }

  .container .inner-page > .col-lg-9, .inner-page .container > .col-md-12 {
    padding: 0 0;
  }

  body header.affix {
    top: -31px;
  }

  .company_info li.address {
    display: inline-block;
  }

  .toolbar ul > li {
    margin-right: 22px;
  }

  #secondary-banner .container .row > div.col-xs-12:first-of-type {
    min-height: 65px;
  }

  .boxed_layout .car_listings {
    padding: 0;
  }

  .select-wrapper.pagination .pull-right, .select-wrapper.pagination .row > div:first-of-type {
    text-align: center;
  }

  .listing_sort .sort-by {
    margin-left: 0;
  }

  .car_listings {
    margin: 0 auto;
  }

  .inventory-wide-sidebar-left > div, .boxed_layout .listing_sort, .boxed_layout .listing-view, .listing-view, .listing_sort, .inventory-heading .row > div, .inner-page.inventory-listing .content-nav {
    padding-right: 0;
    padding-left: 0;
  }

  .car_listings.boxed.boxed_right {
    margin-left: 0;
  }

  .flip .card .back .hover_title {
    margin-top: 35px;
  }

  .boxed_layout .navbar-collapse {
    margin: 0 -15px;
    padding: 0 15px;
  }

  .breadcrumb {
    display: block;
  }

  div.example-tabs .nav-tabs>li, div.example-tabs .nav-tabs>li>a {
    width: inherit;
  }

  div.example-tabs .nav-tabs>li {
    margin-bottom: inherit;
  }

  .nav-tabs {
    border-bottom: 1px solid #DDD;
  }

  table.left_table, table.right_table {
    width: 49%;
    float: left;
  }

  table.right_table {
    float: right;
  }
}

@media (min-width: 568px) {

  .form-row {
    width: 100%;
  }

  header {
    position: absolute;
  }

  input[type="text"], textarea, select, div.styled, input[type="file"] {
    width: 47.7%;
  }

  #secondary-banner {
    margin-top: -21px;
    padding-top: 164px;
  }

  #secondary-banner h2 {
    font-size: 28px;
    padding-top: 33px;
    padding-left: 0;
  }

  #secondary-banner h4 {
    font-size: 16px;
    padding-left: 0;
    margin-bottom: 2px;
  }

  .breadcrumb {
    float: left;
    margin:0;
  }

  .shadow {
    background-position: left top;
    background-repeat: repeat-x;
  }

  .align-right {
    float: left;
    clear: left;
  }

  .car-type-col-1 {
    width: 98%;
    margin: 10px 0;
  }

  .car-type-col-1 h3 {
    font-size: 20px;
    text-align: left;
  }

  .video-link {
    float: left;
    color: #3a3a3a;
  }

  .form-links {
    margin: 0 0 5px -15px;
    float: left;
    width: 100%;
    padding-left: 0;
  }

  .form-links li {
    margin: 0;
    float: left;
    width: auto;
    list-style: none;
  }

  .form-links li a {
    color: #3a3a3a;
    margin: 0 5px 0 0;
    font-size: 12px;
    width: auto;
    font-weight: 600;
    padding-left: 8px;
    padding-right: 8px;
  }

  .img-border {
    margin: 0 28.5%;
  }

  .no-left-padding {
    width: 50%;
    margin-bottom: 10px;
    margin-left: 0;
    padding-left: 0;
  }

  .content h2.error {
    font-size: 120px;
    line-height: 95px;
  }

  .error-message em {
    font-size: 60px;
  }

  header .bottom-header .navbar-default .navbar-brand .logo {
    margin-top:0;
    margin-left: 5px;
    display: block;
    transition-duration: 0s;
  }

  .efficiency-rating img.aligncenter {
    left: 12px;
  }

  .right_carList .check_box_right {
    position: absolute;
    right: -3px;
    top: -9px;
  }

  .car_heading {
    margin-left: 163px;
  }

  .price_tag img {
    width: 70px;
  }

  .wrapper_carList .car-type .col-width-50 {
    width: 100%;
  }

  .right_carList .car-type-list .list_img {
    width: 150px;
  }

  .right_carList .car-type-list .inventory_right .wrapper_carList .car-type {
    width: 55%;
  }

  .right_carList .car-type-list .inventory_right {
    width: 71%;
  }

  .right_carList .car-type-list .inventory_right .wrapper_carList .car-type-price {
    width: 29%;
    float: left;
    padding-left: 0;
    margin-left: 50px;
  }

  .right_carList .car-type-list .inventory_right {
    width: 63%;
  }

  .right_carList .car-type-list .list_img {
    width: 200px;
    margin-top: 0;
  }

  .view_viewport {
    font-size: 12px;
  }

  .car_heading h3 {
    font-size: 22px;
  }

  .car_heading {
    margin-left: -7px;
  }

  .price_tag {
    left: 57%;
    top: -74px;
  }

  .price-tag {
    font-size: 30px;
    font-weight: 600;
    display: block;
    margin: 5px 0;
    text-align: left;
  }

  .price-wraper .price {
    text-align: left;
  }

  .wrapper_carList .car-type-price .price-wraper {
    position: absolute;
    right: 15px;
    width: auto;
  }

  .car_listing {
    width: 420px;
  }

  .footer-contact ul li {
    padding-right: 0;
  }

  .select_view {
    display: none;
  }

  .dropdown .dropdown-menu li.dropdown .dropdown-menu, header .navbar-nav.pull-right>li>.dropdown-menu, header .navbar-nav>li>.dropdown-menu {
    background: none;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a {
    color: #fff;
  }

  .car-rate-block .small-block {
    margin-bottom: 25px;
  }

  .hours_operation table.table {
    text-align: center;
    display: table;
    margin: 0 auto;
    width: 170px;
  }

  #current_size {
    z-index: 568;
  }

  .flip, .flip .card, .flip .card .face {
    height: 200px;
    width: 370px;
  }

  .years-dropdown, .makers-dropdown, .models-dropdown, .body-styles-dropdown, .mileage-dropdown, .transmissions-dropdown, .fuel-economies-dropdown, .conditions-dropdown, .location-dropdown, .prices-dropdown {
    width: 100%;
  }

  .address_details ul {
    padding-left: 40px;
  }

  .car-info .table {
    font-size: 14px;
  }

  header .bottom-header .navbar-default .navbar-brand {
    padding: 15px 0 0 15px;
  }

  footer.design_2 {
    padding-bottom: 64px;
  }

  .inner-page.about-us .testimonials {
    margin-bottom:0;
  }

  .specialize-in {
    margin-top: 0;
  }

  .testimonials, .latest-news, .featured-brand {
    margin-top:0;
  }

  .boxed_layout .car_listings {
    padding: 0;
  }

  #secondary-banner .container .row > .col-xs-12 {
    padding-left: 15px;
  }

  .boxed_layout section.content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .car_listings {
    margin: 0 auto;
  }

  .boxed_layout .navbar-collapse {
    padding: 0;
    margin: 0 -15px;
  }

  .toolbar ul li:last-child {
    margin-right: 15px;
  }

  #features ul {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media only screen and (max-width: 480px) {

  .inventory_right .car-type {
    width: 100%;
  }

  .inventory_right .car-type-price {
    width: 100%;
    padding-left:0;
    clear: right;
  }

  .view_viewport a {
    font-size: 12px;
    padding-right: 6px;
    padding-top: 2px;
  }

  .cat_accordian .price-wrap {
    padding-left:0;
  }

  .choose-list ul li {
    font-size: 13px;
  }

  .video-link {
    padding-right:0;
  }

  .banner-wrap {
    margin-top: 0;
  }

  #slideControls {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 320px) {

  .price_tag {
    display: none;
  }

  .right_carList .car-type-list .inventory_right .view_viewport a.video-link {
    float: none !important;
    display: block;
    text-align: center;
  }

  .pricing_wrapper h3 {
    font-size: 24px;
  }

  .layout-3 .main_pricing .inside span.amt, .layout-4 .main_pricing .inside span.amt {
    font-size: 63px;
    line-height: 63px;
  }

  .layout-3 .main_pricing .inside span.sub1, .layout-4 .main_pricing .inside span.sub1 {
    font-size: 22px;
    top: -31px;
  }

  .layout-3 .main_pricing .inside span.sub, .layout-4 .main_pricing .inside span.sub {
    font-size: 24px;
    top: 0;
    left: -36px;
  }

  .layout-3 .main_pricing .inside span.super, .layout-4 .main_pricing .inside span.super {
    font-size: 22px;
    top: -18px;
  }

  .layout-3 .main_pricing .inside span.slash, .layout-4 .main_pricing .inside span.slash {
    top: -14px;
  }

  .about-us h3, .blog-container h3, .blog-post h3, .side-content h3, .services h3 {
    font-size: 15px;
  }

  .featured-brand .arrow2 {
    top: 23px;
  }

  .car_category {
    width: 100%;
  }

  .cat_accordian {
    width: 100%;
  }

  .vehicle_details {
    padding: 0;
  }
}

@media only screen and (max-width: 1025px) {

  .boxed_layout.margin { margin: 0; }
  .boxed_layout.margin header.affix-top { margin-top: 0; }

  .horizontal_box .video_details a.pull-right {
    float: left !important;
  }

  .layout-4 .inside {
    position: static;
  }

  .cat_table {
    width: 48%;
  }

  .cat_accordian {
    width: 14%;
  }

  .cat_accordian a.video-link {
    font-size: 11px;
  }

  .car_type_heading {
    width: 68%;
  }

  .inventory_right {
    width: 64%;
  }

  .side-content .list {
    float: left;
    margin-right:0;
  }

  .financing_calculator {
    margin-left:0;
    float: left;
    /*margin-top: 29px;
    margin-bottom: 20px;*/
  }

  .inventory_right .wrapper_carList .car-type-price {
    padding-left:0;
    width: 50%;
    margin-left: 50px;
  }

  .inventory_right .wrapper_carList .car-type {
    width: 50%;
  }

  .wrapper_carList .car-type .col-width-50 {
    width: 100%;
  }

  .horizontal_box .video_details a.pull-right {
    float: right !important;
  }

  .horizontal_box h3 {
    margin-bottom: 30px !important;
  }

  .car-type .col-width-50 {
    width: 100%;
    padding:0;
  }

  .form-element input[type=submit] {
    margin-top: 10px;
  }

  header .navbar-default .navbar-nav>.dropdown>a b.caret, header .navbar-default .navbar-nav .dropdown a b.caret {
    display: inline-block;
  }

  header .navbar-nav li.dropdown:hover ul.dropdown-menu, header .navbar-nav li.dropdown .dropdown-menu li.dropdown:hover ul.dropdown-menu {
    display: none;
  }

  header .navbar-nav li.dropdown.open:hover > ul.dropdown-menu {
    display: block;
  }

  header .navbar-nav li.dropdown.open:hover > ul.dropdown-menu ul {
    display: none;
  }
}

@media only screen and (max-width: 800px) {

  .select-wrapper .link-vehicle .form-links {
    margin-top: 10px;
    padding:0;
  }

  .select-wrapper .link-vehicle {
    width: 100%;
  }

  .select-wrapper .col-dropdown {
    width: 50%;
  }

  .row {
    margin:0;
  }

  .inner-page.row {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .video_details.clearfix a {
    font-size: 12px;
  }

  .layout-4 .main_pricing .inside span.sub {
    top: 0;
    left: -36px;
  }

  .blog-content ul li a {
    font-size: 12px;
  }

  .leave-comments form input[type=text] {
    width: 100%;
    margin-left: 0;
  }

  header .bottom-header .navbar-default .navbar-nav>li>a {
    padding: 4px 11px !important;
  }

  .copyright-wrap .social {
    padding-top: 18px;
  }

  .copyright-wrap .social, .copyright-wrap .f-nav {
    float: none;
  }

  .copyright-wrap .f-nav li:first-of-type {
    margin-left: 0;
  }

  footer form input[type=text] {
    padding: 4px 5px !important;
  }
}

@media (min-width: 1169px) and (max-width: 1290px) {

  .boxed_layout .carfax {
    display: none;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1200px) {

  .inventory .title {
    max-width: 700px;
    white-space: normal;
  }

  div img.carfax {
    left: 244px;
  }

  .price .figure {
    font-size: 30px;
    font-weight: 600;
  }

  .inventory .price {
    right: 25px;
    top: 30px;
  }

  .inventory-wide-sidebar-right div.inventory .options-secondary, .inventory-wide-sidebar-left div.inventory .options-secondary {
    display: none;
  }

  .inventory-wide-sidebar-right .sidebar div.inventory .title, .inventory-wide-sidebar-left .sidebar div.inventory .title {
    max-width: 440px;
  }

  .inventory-wide-sidebar-right div.inventory .carfax, .inventory-wide-sidebar-left div.inventory .carfax {
    left: 250px;
    top: 15px;
    width: 75px;
  }

  div.inventory .options-primary {
    margin-bottom: 35px;
  }

  .boxed div.inventory .inventory .title {
    max-width: 275px;
  }

  .boxed div.inventory .inventory .price {
    left: 164px;
  }

  .boxed.boxed_full div.inventory .inventory .price {
    left: 147px;
  }

  .boxed.boxed_full div.inventory .inventory .title {
    max-width: 240px;
  }

  body .inventory_box.car_listings.boxed.boxed_full .col-md-4:nth-child(3n + 1) {
    clear: both;
  }
}

@media (min-width:768px) {

  .left-none {
    float: left;
    width: auto;
    clear: none;
    margin: 0 auto;
  }

  .right-none {
    float: right;
    width: auto;
    clear: none;
    margin: 0 auto;
  }

  h1 a.logo {
    margin: 10px 40%;
  }

  header {
    position: absolute;
  }

  .f-nav {
    float: right;
    width: auto;
    margin: 14px 0 0 0;
    display: block;
  }

  .slide-text-wrap h4 {
    letter-spacing:0;
  }

  #secondary-banner {
    margin-top: 0;
    padding-top: 167px;
  }

  #secondary-banner h2 {
    font-size: 28px;
    padding-top: 10px;
    padding-left: 0;
  }

  #secondary-banner h4 {
    font-size: 17px;
    padding-left: 0;
    margin-bottom: -8px;
  }

  .breadcrumb {
    float: right;
    margin-top: 0;
    margin-bottom: -13px;
    margin-right: 15px;
  }

  .shadow {
    background-position: center top;
    background-repeat: repeat-x;
  }

  .car-type-col-1 {
    float: left;
    width: 67%;
    margin-left: 1%;
  }

  .car-type-col-1 h3 {
    font-size: 20px;
    font-size: 26px;
    margin: 0 0 5px 0;
    padding: 0;
    line-height: 26px;
    font-weight: 600;
    text-align: left;
  }

  .car-type-col-2 {
    width: 50%;
    float: left;
    margin: 6% 0;
  }

  .car-type-col-3 {
    width: 50%;
    float: right;
  }

  .img-border {
    margin: 0 10px 10px 0;
  }

  .no-left-padding {
    width: 50%;
    float: left;
    padding-left: 0;
    margin-left:0;
  }

  .align-right {
    float: left;
    clear: right;
  }

  .page-view {
    float: right;
    margin-right:0;
    margin-top: 10px;
  }

  .car-type-col-3 form {
    float: right;
    width: auto;
    margin-bottom: 5px;
  }

  .price-wrap {
    display: block;
    margin: 30px 0 14px 0;
  }

  .price {
    font-size: 12px;
    display: block;
    text-align: left;
  }

  .price-tag {
    font-size: 30px;
    font-weight: 600;
    display: block;
    margin: 5px 0;
    text-align: left;
  }

  .video-link {
    float: left;
    color: #3a3a3a;
  }

  .form-links {
    float: right;
  }

  .form-links li:nth-child(2) a, .form-links li:nth-child(3) a {
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 0;
  }

  .content h2.error {
    font-size: 180px;
    line-height: 150px;
  }

  .content h2.error i.exclamation {
    line-height: 155px;
  }

  .error-message em {
    font-size: 80px;
  }

  .content .error-message .exclamation {
    margin-right: 40px;
    line-height: 230px;
  }

  header .bottom-header .navbar-default .navbar-brand .logo {
    margin-top: -1px;
    margin-left: 0;
    display: block;
  }

  header .bottom-header .navbar-default .navbar-brand .logo .secondary_text {
    margin-bottom: -12px;
    margin-top: 5px;
  }

  .right_carList .check_box_right {
    position: absolute;
    right: -3px;
    top: -9px;
  }

  .car_heading {
    margin-left: 178px;
  }

  .right_carList .car-type-price .price-wraper {
    padding-left: 39px;
    padding-top: 15px;
  }

  .price_tag img {
    width: 70px;
  }

  .price_tag {
    left: 56%;
    position: absolute;
    top: -70px;
  }

  .wrapper_carList .car-type .col-width-50 {
    width: 90%;
  }

  .right_carList .car-type-list .list_img {
    width: 200px;
  }

  .car_heading h3 {
    font-size: 22px;
  }

  .car_heading {
    margin-left: 211px;
  }

  .view_viewport {
    font-size: 12px;
  }

  .car_listing {
    width: inherit;
  }

  .footer-contact ul li {
    padding-right: 0;
  }

  .select_view {
    display: block;
  }

  header .left_bar {
    display: none;
  }

  .dropdown .dropdown-menu li.dropdown .dropdown-menu, header .navbar-nav.pull-right>li>.dropdown-menu, header .navbar-nav>li>.dropdown-menu {
    background: rgba(0, 0, 0, 0.65);
  }

  .dropdown .dropdown-menu li.dropdown .dropdown-menu>li>a {
    text-indent: 0;
  }

  header .bottom-header .navbar-default .navbar-nav>li>a {
    font-size: 12px;
    padding: 38px 6px 20px 6px !important;
  }

  header.affix .bottom-header, header.affix-top .bottom-header {
    height: initial;
  }

  .flip {
    height: 124px;
  }

  .hours_table {
    text-align: center;
  }

  .car-rate-block .small-block {
    margin-bottom: 25px;
  }

  .hours_operation {
    clear: both;
  }

  .flip .card .back .hover_title {
    margin-top: 10px;
    margin-bottom: -5px;
    font-size: 24px;
  }

  #current_size {
    z-index: 768;
  }

  .flip, .flip .card, .flip .card .face {
    height: 124px;
    width: 230px;
  }

  #bs-example-navbar-collapse-1.in {
    height: auto !important;
    overflow: visible;
  }

  .car-block-wrap .container .row > div {
    text-align: left;
  }

  .years-dropdown, .makers-dropdown, .models-dropdown, .body-styles-dropdown, .mileage-dropdown, .transmissions-dropdown, .fuel-economies-dropdown, .conditions-dropdown, .location-dropdown, .prices-dropdown {
    width: 100%;
  }

  #secondary-banner .container .row {
    display: table;
  }

  #secondary-banner .container .row div {
    float: none;
    display: table-cell;
    vertical-align: bottom;
    height: 84px;
  }

  .container .inner-page > .col-lg-9, .inner-page .container > .col-md-12 {
    padding-left: 0;
    padding-right: 0;
  }

  .services .things_to_consider {
    margin-top:0;
  }

  .inventory-heading .text-right h2 {
    font-size: 28px;
  }

  .inventory-heading .text-right em {
    font-size: 10px;
  }

  .form-links.top_buttons {
    margin: 0 0 0 0;
  }

  .inner-page.about-us .right-container, .inner-page.about-us .featured-brand, .inner-page.about-us .latest-news, .inner-page.about-us .testimonials {
    margin-top:0;
  }

  .side-content .car-info .table-responsive {
    padding-top: 5px;
  }

  .car-info .table {
    font-size: 12px;
  }

  .efficiency-rating ul li.fuel {
    margin-left: -21px;
  }

  .efficiency-rating ul li.fuel img {
    width: 42px;
    top: 16px;
  }

  footer .container .row .col-md-3:nth-of-type(3n) {
    clear: both;
  }

  header .bottom-header .navbar-default .navbar-brand {
    padding: 23px 0 0 0;
  }

  .boxed_layout header .bottom-header .navbar-default .navbar-brand {
    padding: 15px 0 0 0;
  }

  header .bottom-header .navbar-default .navbar-brand .logo .primary_text {
    margin-bottom: 8px;
  }

  header.affix .bottom-header .navbar-default .navbar-brand {
    padding: 15px 0 0 16px;
  }

  .boxed_layout header.affix .bottom-header .navbar-default .navbar-brand {
    padding: 8px 0 0 16px;
  }

  .inner-page.about-us .latest-news {
    margin: 35px 0;
  }

  .boxed_layout .car_listings {
    padding: 0;
  }

  #secondary-banner .container .row > .col-xs-12 {
    padding-left: 0;
  }

  .boxed_layout #secondary-banner .container .row > .col-xs-12 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .listing_sort, .listing-view {
    padding-left: 0;
    padding-right: 0;
  }

  .copyright-wrap .container .row > div, .pagination_container, .boxed_layout .listing_sort, .boxed_layout .listing-view {
    padding-left: 0;
    padding-right: 0;
  }

  .select-wrapper .row {
    margin-left: 0;
    margin-right: -15px;
  }

  .car_listings, .car_listings.sidebar, .car_listings.boxed {
    margin: 0 auto;
  }

  .inventory-wide-sidebar-left > div {
    padding-right: 0;
    padding-left: 0;
  }

  .boxed_layout footer .container .row > div, .boxed_layout .copyright-wrap .container .row > div, .boxed_layout .car-block-wrap .container .row, .boxed_layout .message-wrap .container .row, .boxed_layout .welcome-wrap .container .row {
    /*padding-left: 15px;
    padding-right: 15px;*/
  }

  .pagination_select .row > div.pull-right {
    padding-right: 15px;
  }

  .boxed_layout .recent-vehicles-wrap .row, .welcome-wrap .recent-vehicles-wrap .row {
    margin-left: 0;
    margin-right: 0;
  }

  .select-wrapper ul.top_buttons li {
    float: right;
  }

  .select-wrapper.pagination .row > div:first-of-type {
    text-align: left;
  }

  .car_listings.boxed.boxed_right .inventory_box {
    margin-left: -15px;
  }

  .right-content, .right-content > div, .right-content > div > div {
    clear: inherit;
  }

  .car-info .table>tbody>tr>td:first-child {
    padding-left: 8px;
  }
}

@media only screen and (max-width: 991px) {
  .layout-4 .main_pricing .inside span.super {
    font-size: 32px;
    top: -37px;
  }

  .layout-4 .main_pricing .inside span.amt {
    font-size: 92px;
    line-height: 92px;
  }

  .layout-4 .main_pricing .inside span.sub1 {
    top: -46px;
    font-size: 28px;
  }

  .layout-4 .main_pricing .inside span.slash {
    top: -26px;
    right: 8px;
  }

  .layout-4 .main_pricing .inside span.sub {
    left: -12px;
  }

  .design_2 .footer-contact .back_icon {
    font-size: 160px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1159px){
  .layout-3 .inside, .layout-4 .inside {
    left: 8px;
  }

  .recent_car.car-block .img-flex {
    width: 45px;
  }

  .recent_car.car-block:hover .img-flex i {
    font-size: 1.5em;
    position: absolute;
    top: 50%;
    margin-top: -10.5px;
    left: 50%;
    margin-left: -8.5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .car_listings {
    width: 762px;
    margin: 0 auto;
    padding-right: 15px;
  }

  .boxed_layout .car_listings {
    width: 740px;
  }

  div.inventory .inventory {
    min-height: 195px;
  }

  .sidebar div.inventory .inventory .title, div.inventory .inventory .title {
    max-width: 480px;
    white-space: normal;
    padding-bottom: 3px;
    left: 229px;
  }

  div.inventory .checkbox {
    right: 12px;
  }

  div.inventory .price {
    position: absolute;
    left: initial;
    top: 57px;
    right: 20px;
  }

  div.inventory .video-icon {
    top: 18px;
    right: 14px;
  }

  div.inventory .options-primary {
    left: 229px;
    top: -2px;
  }

  .boxed div.inventory .options-primary {
    width: 198px;
  }

  div.inventory .inventory .title {
    white-space: normal;
    max-width: 480px;
    left: 230px;
  }

  div.inventory .options-secondary {
    display: none;
  }

  .car_listings div.inventory .carfax {
    left: 255px;
    top: 32px;
    width: 75px;
  }

  .inventory_listings.grid div.inventory .title {
    max-width: 200px;
    font-size: 20px;
    margin-bottom: 27px;
  }

  .inventory_listings.grid div.inventory .checkbox {
    top: 7px;
    left: 217px;
  }

  .inventory_listings.grid div.inventory .options-primary {
    width: 180px;
    margin: 8px auto 0 auto;
  }

  .inventory_listings.grid div.inventory .price {
    left: 77px;
    top: -21px;
  }

  .inventory_listings.grid div.inventory .figure, .inventory_listings.grid div.inventory .tax {
    left: -8px;
  }

  .inventory_listings.grid div.inventory .carfax {
    left: 32px;
    width: 50px;
    bottom: 24px;
  }

  .inventory_listings.grid div.inventory .view-details, .inventory_listings.grid div.inventory .view-video, .inventory_listings div.inventory .view-details, .inventory_listings div.inventory .view-video {
    position: absolute;
    top: auto;
    bottom: 10px;
  }

  .price .figure {
    font-size: 30px;
    font-weight: 600;
  }

  .boxed div.inventory .carfax {
    left: 40px;
    bottom: 42px;
    top: auto;
    bottom: 42px;
  }

  .boxed div.inventory .inventory .price {
    left: 169px;
  }

  .boxed div.inventory .inventory .title {
    max-width: 275px;
  }

  .boxed.boxed_full div.inventory .inventory .price {
    left: 169px;
  }

  .select-wrapper ul.top_buttons li {
    margin-bottom: 5px;
  }

  .select-wrapper span.sort-by {
    margin-right: 10px;
  }

  .select_view .align-right {
    margin-right: 0;
    font-size: 13px;
  }

  .pagination_select .row > div {
    padding: 0;
  }

  .left_inventory {
    clear: both;
  }

  .inventory_pagination {
    margin: 0 0 25px;
  }

  .car_listings.boxed.boxed_full {
    margin-left: -15px;
    margin-right: 0;
  }

  body .inventory_box.car_listings.boxed.boxed_full .col-md-4:nth-child(2n + 1) {
    clear: both;
  }

  body .inventory_box.car_listings.boxed.boxed_full .col-md-4:nth-child(3n + 1) {
    clear: none;
  }

  .detail-service div[class*="col-"]:nth-child(5n + 1){
    clear: both;
  }
}

@media (min-width: 992px) {

  .nav {
    margin: 0;
  }

  .left-none {
    float: left;
    width: auto;
    clear: none;
    margin: 0 auto;
  }

  .right-none {
    float: right;
    width: auto;
    clear: none;
    margin: 0 auto;
  }

  h1 a.logo {
    margin: -2px 0;
  }

  .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar {
    margin: 0;
  }

  .default-btn {
    float: left;
    margin: 0;
  }

  .social {
    float: right;
    margin: 34px 0 0 0;
    padding: 0;
  }

  .slide-text-wrap h4 {
    letter-spacing:0;
  }

  #secondary-banner {
    margin-top: 0;
    padding-top: 160px;
  }

  #secondary-banner h2 {
    font-size: 34px;
    padding-left: 0;
  }

  #secondary-banner h4 {
    font-size: 22px;
    padding-left: 0;
    margin-bottom: -6px;
  }

  .breadcrumb {
    float: right;
    margin-top: 0;
    margin-bottom: -11px;
  }

  .shadow {
    background-position: center top;
    background-repeat: repeat-x;
  }

  .car-type-col-1 {
    float: left;
    width: 45%;
    margin-left: 1%;
  }

  .car-type-col-2 {
    width: 10%;
    float: left;
    margin: 6% 0;
  }

  .car-type-col-3 {
    width: 155px;
  }

  .car-type-col-1 h3 {
    font-size: 20px;
  }

  .form-links {
    float: right;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .form-links li {
    float: right;
    width: auto;
  }

  .form-links li a {
    color: #3a3a3a;
    font-size: 12px;
    width: auto;
    font-weight: 600;
    padding-left: 8px;
    padding-right: 8px;
  }

  .video-link {
    float: left;
    color: #3a3a3a;
  }

  .content h2.error {
    font-size: 260px;
    line-height: 250px;
  }

  .error-message em {
    font-size: 100px;
  }

  header .bottom-header .navbar-default .navbar-brand .logo .secondary_text {
    margin-bottom: 0;
    margin-top: 4px;
  }

  .select-form .max-dropdown {
    width: 165px;
  }

  .address_details ul {
    padding-left: 0;
  }

  .car-type .col-width-60 {
    display: none;
  }

  .right_carList .check_box_right {
    position: absolute;
    right: -3px;
    top: -9px;
  }

  .car_heading {
    margin-left: 211px;
  }

  .right_carList .car-type-price .price-wraper {
    padding-left: 15px;
    margin-top: 15px;
  }

  .price_tag img {
    width: 70px;
  }

  .price_tag {
    left: 56%;
    position: absolute;
    top: -70px;
  }

  .wrapper_carList .car-type .col-width-50 {
    width: 90%;
  }

  .view_viewport {
    font-size: 12px;
  }

  .footer-contact ul li {
    padding-right: 0;
  }

  header .left_bar {
    display: block;
  }

  header .bottom-header .navbar-default .navbar-nav>li>a {
    font-size: 14px;
    padding: 38px 15px 20px 15px !important;
  }

  .flip {
    height: 200px;
  }

  .car-rate-block .small-block {
    margin-bottom: 0;
  }

  .hours_operation {
    clear: none;
  }

  .hours_operation table.table {
    text-align: initial;
    width: 100%;
  }

  #current_size {
    z-index: 992;
  }

  .flip, .flip .card, .flip .card .face {
    height: 164px;
    width: 303px;
  }

  .years-dropdown {
    width: 87px;
  }

  .makers-dropdown {
    width: 94px;
  }

  .models-dropdown {
    width: 99px;
  }

  .body-styles-dropdown {
    width: 121px;
  }

  .mileage-dropdown {
    width: 98px;
  }

  .transmissions-dropdown {
    width: 136px;
  }

  .fuel-economies-dropdown {
    width: 144px;
  }

  .conditions-dropdown {
    width: 116px;
  }

  .location-dropdown {
    width: 110px;
  }

  .prices-dropdown {
    width: 86px;
  }

  .price-ascending-dropdown {
    width: 133px;
  }

  .about_team ul li {
    line-height: 34px;
  }

  .about_team ul li i {
    top: 6px;
  }

  .about_team ul li i.fa-mobile {
    top: 6px;
  }

  .about_team ul li i.fa-envelope-o {
    top: 11px;
  }

  .inventory-heading .text-right h2 {
    font-size: 33px;
  }

  .inventory-heading .text-right em {
    font-size: 14px;
  }

  .efficiency-rating ul li.fuel {
    margin-left: -25.5px;
  }

  .efficiency-rating ul li.fuel img {
    width: 51px;
    top: 13px;
  }

  .side-content .car-info .table-responsive {
    padding-top:0;
  }

  .car-info .table {
    font-size: 14px;
  }

  footer .container .row .col-md-3:nth-of-type(3n) {
    clear: none;
  }

  .boxed_layout header .bottom-header .navbar-default .navbar-nav>li>a {
    font-size: 14px;
    padding: 38px 10px 20px !important;
  }

  .inner-page.inventory-listing .content-nav {
    padding: 0;
  }

  header .bottom-header .navbar-default .navbar-brand {
    padding: 22px 0 0 0;
  }

  header.affix .bottom-header .navbar-default .navbar-brand {
    padding: 15px 0 0 0;
  }

  #secondary-banner .container .row div {
    float: none;
    display: table-cell;
    vertical-align: bottom;
    height: 92px;
    padding-right: 0;
  }

  .inner-page.about-us .latest-news {
    margin: 0;
  }

  .blog-content img.featured_image {
    display: block;
    float: none;
    margin: 7px auto 10px;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .boxed_layout div.inventory {
    margin: 0 0 20px;
  }

  /*.boxed_layout header .toolbar .container, .boxed_layout header .bottom-header .container {
      padding: 0 0;
  }*/

  .toolbar .left_bar, .toolbar .container .row > div, .listing_sort, .listing-view {
    padding-left: 0;
    padding-right: 0;
  }

  .copyright-wrap .container .row > div, .pagination_container {
    padding-left: 0;
    padding-right: 0;
  }

  .boxed_layout section.content {
    padding-left: 0;
    padding-right: 0;
  }

  .select-wrapper span.sort-by {
    margin-right: 10px;
  }

  .boxed_layout .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .boxed_layout #secondary-banner .container .row > .col-xs-12 {
    padding-left: 0;
  }

  .select-wrapper ul.top_buttons li {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .select-wrapper.pagination .row > div:first-of-type {
    text-align: left;
  }

  .select-wrapper ul.top_buttons {
    margin-right: -5px;
  }

  .car_listings.boxed.boxed_right {
    margin-left: -15px;
    margin-right: 15px;
  }

  .select_view .align-right {
    float: none;
    vertical-align: top;
  }

  .select_view {
    text-align: right;
  }

  .select_view .page-view.nav {
    display: inline-block;
    float: none;
  }

  /*.boxed_layout .container {
      width: 970px;
      padding-left: 0;
      padding-right: 0;
  }*/

  .inventory-wide-sidebar-left .sidebar.car_listings {
    padding-left: 15px;
  }

  .car_listings.boxed.boxed_left {
    margin-left: 15px;
  }

  .car_listings.boxed.boxed_right .inventory_box {
    margin-left: 0;
  }

  header .bottom-header .navbar-default .navbar-brand .logo .primary_text {
    margin-bottom: 10px;
  }

  .flip .card .back .hover_title {
    margin-top: 25px;
    margin-bottom: -5px;
    font-size: 24px;
  }

  .inventory-wide-sidebar-right .car_listings.sidebar { margin-left: auto; }
}

@media (min-width: 1200px) {

  .car_heading {
    margin-left: 214px;
  }

  .wrapper_carList .car-type .col-width-50 {
    width: 44%;
  }

  .flip .card .back .hover_title {
    margin-top: 40px;
    margin-bottom: 14px;
    font-size: 38px;
  }

  .flip, .flip .card, .flip .card .face {
    height: 200px;
    width: 370px;
  }

  .search-form .find_new_vehicle {
    margin-top: 0;
  }

  .flip, .flip .card, .flip .card .face {
    height: 200px;
    width: 370px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 480px) {

  .car_listings {
    width: 455px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

  .boxed_layout .car_listings {
    width: 455px;
  }

  div.inventory .preview, .boxed div.inventory .preview {
    position: relative;
    margin-bottom: 60px;
    float: left;
    top: 7px;
  }

  .sidebar div.inventory .inventory .title, div.inventory .inventory .title, .boxed div.inventory .inventory .title {
    left: 11px;
    max-width: 335px;
    margin-bottom: 3px;
    word-break: break-word;
    white-space: normal;
  }

  div.inventory .checkbox {
    right: 12px;
  }

  div.inventory .options-primary, .boxed div.inventory .options-primary {
    left: 24px;
    top: 3px;
    width: 185px;
    margin: 0;
    position: relative;
  }

  div.inventory .options-secondary {
    display: none;
  }

  .car_listings div.inventory .carfax {
    right: 95px;
    bottom: 13px;
    left: auto;
    width: 59px;
    top: auto;
    position: absolute;
  }

  .car_listings div.inventory .price, .boxed.boxed_left div.inventory .price, .boxed.boxed_right div.inventory .price {
    bottom: 42px;
    top: auto;
    left: 224px;
    margin: 0;
    position: absolute;
    right: 50px;
  }

  div.inventory .figure, .boxed div.inventory .figure {
    left: auto;
    top: -19px;
    font-weight: 600;
    font-size: 30px;
    right: -63px;
  }

  div.inventory .tax, .boxed div.inventory .tax {
    left: 85px;
    top: -22px;
  }

  div.inventory .view-video {
    right: initial;
    left: 10px;
    bottom: 13px;
    top: initial;
    position: absolute;
  }

  div.inventory .video-icon {
    left: -5px;
    top:0;
  }

  div.inventory .view-details {
    bottom: 13px;
    top: auto;
  }

  .view-button:active {
    top: -12px;
  }

  .boxed div.inventory .price b {
    position: relative;
    left: auto;
    top: auto;
  }

  .car_listings.boxed.boxed_full div.col-lg-3 {
    padding: 0;
  }

  .boxed.car_listings .col-xs-12 {
    padding: 0;
  }

  .post-tags {
    padding: 0;
    margin-top: 10px;
  }

  .blog-content ul li {
    margin-bottom: 5px;
  }

  .address_details ul {
    padding-left: 0;
  }

  .car_listings.boxed.boxed_full {
    margin: 0 auto;
  }

  .top_buttons li {
    display: inline-block;
  }

  .select-wrapper ul.top_buttons {
    padding: 0;
    text-align: center;
    margin: 10px auto;
    float: none;
    height: 25px;
    display: inline-block;
  }

  .select-wrapper span.sort-by {
    margin-right: 10px;
  }

  .price-ascending-dropdown {
    text-align: left;
  }

  .select-wrapper span.sort-by {
    float: none;
    vertical-align: top;
  }

  .pagination_select .row > div:first-of-type {
    text-align: center;
  }

  .things_to_consider h3 {
    margin-top: 35px;
  }

  .featured-brand {
    margin-top: 0;
  }

  .featured-service {
    padding: 22px 0 10px 0;
  }

  .detail-service {
    padding: 0 0 20px 0;
  }

  .find_team .row .team {
    margin-bottom: 35px;
  }

  .inventory_pagination {
    margin: 0 0 25px;
  }

  .car_listings.boxed {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 479px) {

  .car_listings {
    width: 300px;
    margin: 0 auto;
    padding: 0;
  }

  /* listing */


  div.inventory .preview {
    position: relative;
    margin: 0 auto;
    left: 49px;
    top: 0;
    float: none;
  }

  div.inventory .inventory .title, .sidebar div.inventory .inventory .title {
    left: 11px;
    max-width: 260px;
    word-break: break-word;
    white-space: normal;
  }

  div.inventory .checkbox {
    right: 12px;
  }

  div.inventory .options-primary {
    left: 48px;
    top: 11px;
    width: 225px;
    margin-bottom: 120px;
  }

  div.inventory .options-secondary {
    display: none;
  }

  .car_listings div.inventory .price {
    left: 48px;
    bottom: -6px;
    top: initial;
    right: 50px;
    margin: 3px 0 40px 0;
  }

  .car_listings div.inventory .carfax {
    position: absolute;
    left: 50px;
    bottom: 42px;
    width: 58px;
    top: auto;
  }

  div.inventory .figure {
    left: 89px;
    top: -20px;
    font-weight: 600;
    font-size: 30px;
  }

  div.inventory .tax {
    left: 90px;
    top: -25px;
  }

  div.inventory .view-video {
    left: 11px;
    bottom: 10px;
    top: auto;
    font-size: 12px;
    position: absolute;
  }

  div.inventory .video-icon {
    left: -5px;
    top: -20px;
  }

  div.inventory .view-details {
    bottom: 10px;
    font-size: 12px;
    top: auto;
  }

  .boxed.car_listings, .boxed.car_listings .col-xs-12 {
    padding: 0;
  }

  .boxed.car_listings div.inventory .inventory .price {
    left: 147px;
    right: initial;
    bottom: initial;
    top: -22px;
  }

  .boxed.car_listings div.inventory .inventory .figure {
    left: -10px;
  }

  .boxed.car_listings div.inventory .inventory .tax {
    left: -9px;
  }

  .select-wrapper ul.top_buttons {
    margin: 10px 0;
  }

  .select-wrapper ul.top_buttons li {
    display: inline-block;
    margin-bottom: 5px;
  }

  .select-wrapper ul.top_buttons li a {
    float: none;
    display: inline-block;
  }

  .select_view {
    display: none;
  }

  .things_to_consider h3 {
    margin-top: 35px;
  }

  .featured-brand {
    margin-top: 0;
  }

  .detail-service {
    padding: 0 0 20px 15px;
  }

  .post-tags {
    padding: 0;
    margin-top: 10px;
  }

  .blog-content ul li {
    margin-bottom: 5px;
  }

  .address_details ul {
    padding-left: 0;
  }

  .inventory_pagination {
    margin: 0 0 25px;
  }

  .car_listings.boxed.boxed_full {
    margin: 0 auto;
  }

  .car_listings.boxed {
    margin-left: 0;
    margin-right: 0;
  }

  .parallax_scroll h1 {
    font-size: 60px;
    margin-bottom: 10px;
  }
}

@media (min-width:320px) and (max-width: 320px) {

  /*typo*/


  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 30px;
  }

  /*typo*/



  body {
    overflow-x: hidden;
  }

  h1 a.logo {
    margin-bottom: 20px;
  }

  .left-none, .right-none {
    float: none;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
  }

  header {
    position: absolute;
  }

  #secondary-banner {
    margin-top: -21px;
    padding-top: 164px;
  }

  .slide-text-wrap h2 {
    font-size: 40px;
  }

  .slide-text-wrap h3 {
    font-size: 30px;
  }

  .slide-text-wrap h4 {
    font-size: 20px;
  }

  .default-btn {
    float: none;
    display: block;
    clear: both;
    margin-top: 10px;
  }

  .message-wrap h2 {
    text-align: center;
  }

  .f-nav li {
    float: none;
    display: inline;
    text-align: center;
  }

  .social li:nth-child(5n) {
    clear: right;
  }

  .border-box {
    width: 100%;
  }

  .form-row {
    width: 100%;
  }

  input[type="text"], textarea, select, div.styled, input[type="file"] {
    width: 45%;
  }

  #secondary-banner h2 {
    font-size: 28px;
    padding-top: 0;
    padding-left: 0;
    margin-top: 10px;
  }

  #secondary-banner h4 {
    font-size: 16px;
    padding-left: 0;
    margin-bottom: 2px;
  }

  .breadcrumb {
    float: left;
    margin:0;
  }

  .shadow {
    background-position: left top;
    background-repeat: repeat-x;
  }

  .ribbon {
    margin-right: 25px;
  }

  .ribbon strong {
    font-weight: 600;
  }

  .ribbon-item {
    display: block;
  }

  .page-view {
    float: right;
    margin-left: 0;
  }

  .form-selectbox label {
    left: -28px;
  }

  .form-links {
    float: left;
    width: 100%;
    padding-left: 0;
    margin-bottom: 10px;
  }

  .form-links li {
    display: block;
    width: 100%;
    margin-bottom: 8px;
  }

  .form-links li a {
    text-align: center;
    display: block;
    width: 100%;
    color: #3a3a3a;
    font-size: 12px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .car-type-col-1, .car-type-col-2, .car-type-col-3 {
    display: block;
    width: 100%;
    margin: 10px 0;
  }

  .car-type-col-1 h3 {
    text-align: center;
    font-size: 18px;
  }

  .no-left-padding {
    width: 100%;
    margin-bottom: 10px;
  }

  .car-type-col-2 img {
    margin: 10px auto;
    clear: both;
  }

  .price-tag {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    display: block;
  }

  .price {
    font-size: 12px;
    text-align: center;
    display: block;
  }

  .img-border {
    margin: 10px 15px;
    display: block;
  }

  .video-link {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    color: #3a3a3a;
  }

  .car-type-col-3 form {
    float: right;
    width: auto;
    margin-bottom: 5px;
  }

  .price-wrap {
    display: block;
    margin: 30px 0 14px 0;
    clear: both;
  }

  .content h2.error {
    font-size: 40px;
  }

  .error-message em {
    font-size: 30px;
  }

  .content .error-message .exclamation {
    margin-right: 20px;
  }

  header .bottom-header .navbar-default .navbar-brand .logo {
    margin-top: 15px;
    margin-left: 5px;
    display: block;
  }

  .efficiency-rating img.aligncenter {
    left: 12px;
  }

  .view_viewport {
    font-size: 12px;
  }

  .example-tabs .nav li {
    width: 100%;
    text-align: center;
  }

  .footer-contact ul li {
    padding-right: 0;
  }

  header .left_bar {
    display: none;
  }

  .dropdown .dropdown-menu li.dropdown .dropdown-menu {
    background: none;
  }

  .dropdown .dropdown-menu li.dropdown .dropdown-menu>li>a {
    text-indent: 20px;
  }

  .toolbar .right-none.pull-right {
    float: right;
    width: auto;
    clear: none;
    margin: 0 auto;
  }

  .car-rate-block .small-block {
    margin-bottom: 25px;
  }

  #current_size {
    z-index: 320;
  }

  .flip, .flip .card, .flip .card .face {
    height: 192px;
    width: 100%;
  }

  .car-block-wrap .container .row > div {
    margin: 0 auto 50px;
    text-align: center;
  }

  .car-block-wrap .container .row .flip {
    margin: 0 auto;
  }

  .toolbar .row ul li.address {
    display: none;
  }

  .select_view {
    display: none;
  }

  .years-dropdown, .makers-dropdown, .models-dropdown, .body-styles-dropdown, .mileage-dropdown, .transmissions-dropdown, .fuel-economies-dropdown, .conditions-dropdown, .location-dropdown, .prices-dropdown {
    width: 100%;
  }

  #secondary-banner .container .row {
    display: block;
  }

  #secondary-banner .container .row div {
    float: none;
    display: block;
    vertical-align: bottom;
  }

  .content .error-message {
    padding-top: 40px;
    padding-bottom: 55px;
  }

  .container .inner-page > .col-lg-9, .inner-page .container > .col-md-12 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .address_details ul {
    padding-left: 0;
  }

  .services .things_to_consider {
    margin-top: 30px;
  }

  .about_team ul li {
    line-height: 24px;
  }

  .about_team ul li i {
    top: 6px;
  }

  .about_team ul li i.fa-mobile {
    top: 5px;
  }

  .about_team ul li i.fa-envelope-o {
    top: 6px;
  }

  .form-links.top_buttons {
    margin: 15px 0;
  }

  .inner-page.about-us .right-container, .inner-page.about-us .featured-brand, .inner-page.about-us .latest-news, .inner-page.about-us .testimonials {
    margin-top: 40px;
  }

  .side-content .car-info .table-responsive {
    padding-top: 5px;
  }

  .flip, .flip .card, .flip .card .face {
    height: 200px;
    width: 100%;
  }
}