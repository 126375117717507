// ============================================================================
// Overrides
// ============================================================================

#ForgotPassword {
  display: inline-block;
  margin: 20px 10px;
}

#__bs_notify__ {
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  border-radius: 0 !important;
}

.w-lightbox-backdrop {
  z-index: 9000 !important;
}

.gallery-image {
  margin-bottom: 15px;
}

.breadcrumb {
  li {
    &:before {
      content: '' !important;
    }
  }
}

.inventory {
  .title {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    margin: 5px auto;
    font-size: 18px;
    @media (min-width: $screen-lg-min) {
      font-size: 24px;
    }
  }

  h5 {
    font-size: 18px;
    @media (min-width: $screen-lg-min) {
      font-size: 20px;
    }
  }
}

.form_vehicle {
  form {
    .field {
      margin-top: 0;

      .left {
        float: left;
      }
    }
  }
}

.caption_background {
  background-color: rgba(0, 0, 0, .2);
  padding: 15px 30px;

  * {
    margin: 0 !important;
    color: #fff !important;
  }
}

.price {
  top: 0 !important;
  @media (min-width: 479px) and (max-width: 768px) {
    top: auto !important;
  }
}

form {
  fieldset {
    label {
      position: relative;
      top: auto;
      right: auto;

      &:before {
        display: none !important;
      }
    }

    input {
      width: 100% !important;
    }
  }
}

header.affix .container .navbar .navbar-brand img {
  height: 100%;
}

.find_map h2, .information_head h3 {
  font-weight: normal;
  font-size: 24px;
}

h1 {
  font-size: 50px;
}

.caption_background {
  background-color: rgba(0, 0, 0, 0.4);
}

/*
#secondary-banner h2 {
  background-color: rgba(0,0,0,0.4);
  padding: 10px;
  width:auto;
}
*/

.footer-contact ul li p {
  line-height: 1.3em;
}

.site-map a {
  text-decoration: none;
}

header .navbar-default .navbar-nav .dropdown a .caret, header .navbar-default .navbar-nav > .dropdown > a .caret {
  display: inline-block;
}

h2 {
  font-size: 30px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif, Arial;
  letter-spacing: -2px;
}

div.inventory {
  border: none;
  box-shadow: none;
  background-image: none;
  border-radius: 0;

  a {
    font-size: 16px;
  }

  img {
    margin: 0;
    padding: 0;
  }
}

.boxed div.inventory .preview {
  border: none;
}

.left_inventory h3 {
  font-weight: 600;
}

.efficiency-rating, .listing-slider {
  box-shadow: none;
}

.car-info {
  margin-left: 0px;
  text-align: left;
  @media (min-width: $screen-sm-min) {
    margin-left: 20px;
    text-align: left;
  }

  h3 {
    text-align: left;
  }
}

ul.social {
  float: left;

  li {
    padding-left: 0;
    padding-right: 25px;
  }
}

p + p {
  margin-top: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

a.text-black, a.text-black:hover {
  color: #000 !important;
}

.flexslider {
  .flex-direction-nav {
    li {
      a {

        &:before {
          color: #c7081b;
        }
      }
    }
  }
}

//.row {
//  margin-left: -15px;
//  margin-right: -15px;
//}

//header {
//  background: url(../images/bg-tread-thin-light.png);
//  background-position: bottom;
//  background-repeat: repeat-x;
//  background-color: #e9202b;
//  background-size: cover;
//}
header {
  //background: url(../images/bg-clear.png);
  //background-color: transparentize($theme-primary, .20);
  background-image: $background-bg;
  background-color: #f00;
  @media (min-width: $screen-sm-min) {
    background-color: transparent;
  }
}

.bottom-header .navbar-default .navbar-nav > .active > a, .bottom-header .navbar-default .navbar-nav > .active > a:focus, .bottom-header .navbar-default .navbar-nav > .active > a:hover {
  background-color: rgba(95, 95, 95, 0.51);
}

//#secondary-banner {
//  background-size: cover;
//  height: 400px;
//  background-position: center;
//}
#secondary-banner {
  background-size: cover;
  height: 420px;
  background-position: center -380px;
  background-attachment: scroll;
  @media only screen and (max-width: 1024px) {
    background-attachment: scroll;
    background-position: center;
  }
}

#banner-title {
  padding-top: 85px;
}

.toolbar {
  background: transparentize(darken($theme-primary, 10%), .30);
}

i.fa.fa-search {
  color: #fff;
}

.toolbar ul li {
  color: #fff;
}

footer {
  background-image: url(../images/bg-tread-grey-transparent.png);
  background-color: #3e3e3e;
  padding: 37px 0 54px;
  background-position: bottom;
  background-repeat: repeat-x;
}

//.message-wrap {
//  background: url(../images/body_bg_1.png);
//  border-bottom: solid 2px #fff;
//  border-top: solid 3px #f00;
//}

.message-wrap {
  background: url(../images/bg-tread-grey-transparent.png) bottom repeat-x #2b2b2b;
  border-top: none;
  background-size: contain;
}

.alternate-font {
  font-size: 30px;
  font-family: 'Anton', sans-serif;
  color: $theme-primary-bright;
  font-style: italic;
  text-shadow: 3px 1px #131313;
  margin: auto;
  @media (min-width: $screen-xs-min) {
    font-size: 35px;
  }
  @media (min-width: $screen-sm-min) {
    font-size: inherit;
  }
}

.price-font {
  font-size: inherit;
  font-family: 'Anton', sans-serif;
  color: $theme-primary-bright;

  h1 {
    font-weight: 800;
    font-size: 40px;

    span {
      font-weight: 400;
      font-size: 18px;
    }
  }
}

.gst {
  font-weight: 400;
  font-size: 12px;
}

.parallax_parent.design_2 .parallax_scroll .overlay {
  background-color: rgba(255, 255, 255, 0.78);
}

@media (min-width: 1200px) {
  .container {
    width: 1270px;
  }
}

.copyright-wrap {
  border-top: solid 3px rgb(43, 43, 43);
}

.affix {
  .container {
    .navbar {
      .navbar-header {
        .nav-brand {
          img {
            @media (min-width: $screen-md-min) {
              height: 75%;
            }
            @media (min-width: $screen-lg-min) {
              height: 125% !important;
            }
          }
        }
      }

      .navbar-collapse {
        .navbar-nav {
          > li {
            > a {
              @media (min-width: $screen-sm-min) {
                padding: 14px 5px 15px 9px !important;
              }
              @media (min-width: $screen-md-min) {
                padding: 10px 5px 10px !important;
                font-size: 12px;
              }
              @media (min-width: $screen-lg-min) {
                padding: 38px 6px 20px !important;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

header {
  .bottom-header {
    .navbar-header {
      .nav-brand {
        img {
          @media (min-width: $screen-md-min) {
            height: 72%;
          }
          @media (min-width: $screen-lg-min) {
            height: 125%;
          }
        }
      }
    }

    .navbar-default {
      .navbar-nav {
        @media (min-width: $screen-md-min) {
          float: right;
        }

        > li {
          > a {
            @media (min-width: $screen-sm-min) {
              padding: 4px 11px !important;
            }
            @media (min-width: $screen-md-min) {
              padding: 10px 5px 10px !important;
              font-size: 12px;
            }
            @media (min-width: $screen-lg-min) {
              padding: 38px 6px 20px !important;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.flip .card .back, .flip .card .front {
  -webkit-transition-delay: .2s;
  -webkit-transform-style: preserve-3d;
  -moz-transition-delay: .2s;
  -moz-transform-style: preserve-3d;
  -o-transition-delay: .2s;
  -o-transform-style: preserve-3d;
  -ms-transition-delay: .2s;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  transition: initial;
  transition-delay: .2s;
  transition-property: fade;
}

.morecontent span {
  display: none;
}

.morelink {
  display: block;
}

.about_team.padding-bottom-10 {
  border-bottom: none;
}

.inner-page.services {
  margin-top: 5px;
  margin-bottom: 5px;
}

.side-blog img.alignleft {
  margin: 6px 15px 0px 0px;
}

.side-blog p {
  line-height: 18px;
  margin-top: 6px;
  margin-left: 6px;
  margin-bottom: 0;
}

.side-blog strong {
  color: #c7081b;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  display: block;
}

.copyright-wrap {
  background: url(../images/bg-stripe-tile-gray.jpg);
}

.toolbar .search_box, .toolbar ul li a {
  font-weight: 800;
  color: #fff;
}

//header {
//  .navbar-brand img {
//    height: 125%;
//    width: auto;
//    margin-top: -15px;
//  }
//}

h1.col-lg-9.col-md-8.col-sm-12.col-xs-12.xs-padding-left-15 {
  margin: auto;
}

.row.hidden-xs.hidden-sm h1 {
  font-weight: 800;
  font-family: "Open Sans";
  font-size: 60px;
}

#secondary-banner h2 {
  font-size: 50px;
  font-weight: 200;
}

.alternate-font h1 {
  font-weight: 800;
  font-family: "Open Sans";
  font-size: 40px;
}

.margin-top-25, .margin-vertical-25 {
  margin-top: 0px !important;
}

.featured {
  color: black;
  text-align: center;
  background-color: #f0f0f0;
  padding: 20px 20px;
  margin-top: 26px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  -ms-transition-duration: 250ms;
  padding-bottom: 10px;
}

.featured:hover {
  background-color: #c7081b;
  color: #fff;
}

.car-block-bottom {
  background-color: #828282;
  background-image: url(../images/bg-tread-grey-transparent.png);
  background-position: bottom;
  background-size: cover;
}

.car-block {
  &:hover {
    .car-block-bottom {
      z-index: 1000;
    }
  }
}

.car-block:hover .car-block-bottom h4 {
  color: #fff;
}

.car-block .car-block-bottom h4 {
  color: #fff;
  font-size: 1.5em;
  padding-bottom: 10px;
}

.car-block-bottom h5 {
  color: #fff;
}

.fixed-bg {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  min-height: 385px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .7);
  }
}

.center-services i.fa {
  display: block;
  font-size: 84px;
  color: #2d2d2d;
  line-height: 84px;
}

.center-services h3 {
  font-weight: 600;
  margin: 20px 0 14px;
  letter-spacing: -1px;
  font-size: 29px;
  font-family: 'Open Sans', sans-serif, Arial;
}

.center-services {
  text-align: center;
  margin-bottom: 60px;
  color: #2d2d2d;
  padding-top: 60px;
  margin-top: 0;
}

td.spec {
  font-size: large;
  font-style: italic;
  font-weight: 800;
}

a.morebtn {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
}

.carasouel-slider3 .col-md-3 {
  padding-bottom: 20px;
}

.nav-brand {
  padding: 22px 0 0;
  height: 75px;
  overflow: hidden;
  margin-left: -15px;
  float: left;
  @media (max-width: 768px) {
    padding-left: 20px;
    height: 59px;
  }
}

header {
  .nav-brand img {
    height: 125%;
    width: auto;
    margin-top: -15px;
  }
}

a.sbSelector:link, a.sbSelector:visited, a.sbSelector:hover {
  font-size: 15px;
  top: 2px;
}

.sbHolder {
  height: 36px;
}

.sbHolder .sbToggle {
  top: 10px;
}

.btn-inventory.md-button {
  font-size: 15px;
  padding: 8px 20px;
}

.nav-hr {
  margin-top: 0px;
  margin-bottom: 0px;
}

.car-block {
  height: 240px;
  width: 300px;
  @media (min-width: $screen-sm-min) {
    height: 240px;
    width: 300px;
  }
  @media (min-width: $screen-md-min) {
    height: 190px;
    width: initial;
  }
  @media (min-width: $screen-lg-min) {
    height: 240px;
    width: initial;
  }
}

.car-block-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50px;
}

.home-car-block {
  height: 330px;
  width: initial;
  @media (min-width: $screen-sm-min) {
    height: 245px;
    width: initial;
  }
  @media (min-width: $screen-md-min) {
    height: 275px;
    width: initial;
  }
  @media (min-width: $screen-lg-min) {
    height: 330px;
    width: initial;
  }
}

.home-bottom {
  min-height: 103px;
  height: initial;
}

.event-car-block {
  height: 305px;
  width: initial;
  @media (min-width: $screen-sm-min) {
    height: 245px;
    width: initial;
  }
  @media (min-width: $screen-md-min) {
    height: 250px;
    width: initial;
  }
  @media (min-width: $screen-lg-min) {
    height: 305px;
    width: initial;
  }
}

.h1-center {
  text-align: center;
}

.title {
  text-align: center;
}

.dark-font {
  font-size: 25px;
  font-weight: 600;
  line-height: 16px;
}

.contact_address {
  p {
    margin-bottom: 5px;
    line-height: 20px;
  }
}

.hide-me {
  display: none;
  @media (min-width: $screen-sm-min) {
    display: block;
  }
}

.banner-wrap {
  margin-top: 30px !important;
  @media (min-width: $screen-sm-min) {
    margin-top: 0px !important;
  }
}

.table-responsive {
  overflow: auto;

  .table > tbody > tr > td {
    white-space: normal;
  }
}