// ============================================================================
// Home Page Layout
// ============================================================================

.hover_description {
  color: #FFF;
  font-size: 14px;
}

.message-wrap {
  h1 {
    font-size: 35px;
    margin: auto;
    // line-height: ;
    @media (min-width: $screen-sm-min) {
      font-size: 42px;
      margin: auto;
      // line-height: ;
    }
    @media (min-width: $screen-md-min) {
      font-size: 50px;
      margin: auto;
      // line-height: ;
    }
  }
}

.tp-caption {
  margin-top: 30px !important;
  @media (min-width: $screen-sm-min) {
    margin-top: 0;
  }
  a {
    color: #fff;
    &:hover {
      color: #fff;
      background-color: #B8091B;
    }
  }
}
