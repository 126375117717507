// ============================================================================
// Search
// ============================================================================

.inline-search {
  .input-group-btn .btn, .form-control {
    background-color: transparent;
    border: 0;
    height: 30px;
  }
  .input-group-btn {
    z-index: 2;
    .btn {
      color: #929596;
      &:hover, &:active, &:focus {
        color: #FFF;
      }
    }
  }
  .form-control {
    color: #FFF;
    &:hover, &:active, &:focus {
      outline: none;
      box-shadow: none;
      border: 0;
    }
  }
}

.search-query {
  .inline-search {
    .input-group-btn {
      .btn {
        color: #929596;
        &:hover, &:active, &:focus {
          color: #000;
        }
      }
    }
    .form-control {
      color: #000;
    }
  }
}