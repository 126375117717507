// ============================================================================
// Typography Stylesheet
//
// Defines additional styles which can be applied in the CMS
// ============================================================================

// ATTENTION: Button styles are now in 'editor.scss' due to the unfortunate repercussions of using @extend

// Bootstrap Styles
// Styles which you want users to be able to select in the CMS
// ----------------------------------------------------------------------------

.lead {
  // For the style to register, it needs some kind of definition.
  // Just apply the default display value
  display: block;
}

* {
  word-wrap: break-word;
}

h1 {
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h2 {
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h3 {
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h4 {
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h5 {
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h6 {
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

ol {

}

ul {

}

p {

}

a {
  transition: all ease 500ms;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

hr {

}

blockquote {

}

// Custom Styles
//
// Styles which will appear in the Styles dropdown in the CMS
// ----------------------------------------------------------------------------

.no-break {
  white-space: nowrap;
}
.Impact-Red {
  margin: 28px 0 12px 0;
  display: block;
  color: #dd0611;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif, Arial;
  letter-spacing: -0.5px;
  text-decoration: none;
}
.Impact-Heading{
  font-size: 1.7em;
  font-family: Anton,sans-serif;
  color: #c7081b;
  font-style: italic;
}
.Impact-Heading-White {
  font-family: Anton,sans-serif;
  color: #fff;
  font-style: italic;
}