// ============================================================================
// Scaffolding
// ============================================================================

body {

}

.dp-bl {
  width: 100%;
  display: block;
}

a[data-scroll] {
  cursor: pointer;
}

.strong {
  font-weight: bold;
}

.fake-link {
  color: #0000FF;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.img-centered {
  &.img-responsive {
  margin-left: auto;
  margin-right: auto;
  }
  .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }
}

.img-restricted {
  &.img-responsive {
    max-width: 300px;
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
  }
  .img-responsive {
    max-width: 300px;
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
  }
}

.map {
  min-height: 200px;
  @media (min-width: $screen-sm-min) {
    min-height: 400px;
  }
}

.finance-form {
  // It's not perfect but it is the best I can do without JS which is not allowed:
  // http://stackoverflow.com/questions/35781928/iframe-auto-height-on-content-change-within-iframe
  min-height: 1770px;
  @media (min-width: $screen-xs-min) {
    min-height: 1540px;
  }
  @media (min-width: $screen-sm-min) {
    min-height: 1110px;
  }
  @media (min-width: $screen-md-min) {
    min-height: 970px;
  }
}

.business-finance-form {
  // It's not perfect but it is the best I can do without JS which is not allowed:
  // http://stackoverflow.com/questions/35781928/iframe-auto-height-on-content-change-within-iframe
  min-height: 1400px;
  @media (min-width: $screen-xs-min) {
    min-height: 1300px;
  }
  @media (min-width: $screen-sm-min) {
    min-height: 1300px;
  }
  @media (min-width: $screen-md-min) {
    min-height: 1300px;
  }
}