// ============================================================================
// Buttons
// ============================================================================

.btn-theme-primary {
  @extend .btn;
  white-space: normal !important;
  word-wrap: break-word;
  transition: all ease 500ms;

  &:hover, &:focus, &:active {
    border: 0;
    outline: 0;
  }
}

.btn-primary {
  color: #fff;
  background-color: #eb1c28;
  border-color: #eb1c28;

  &:hover, &:focus, &:active {
    background-color: darken(#eb1c28, 5%);
    border-color: darken(#eb1c28, 5%);
  }
}